<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nKey = 'POS_CHECK'
const i18nCommon = 'COMMON'

export default {
    mixins: [vuetableFormatter],
    data() {
        return {
            posCheckFields: [
                {
                    name: 'user.name',
                    title: this.getI18n('USERS.TITLES', 'user'),
                    sortField: 'users|users.name',
                },
                {
                    name: 'pointOfSale.name',
                    title: this.getI18n('POS.TITLES', 'pos'),
                    sortField: 'point_of_sales|point_of_sales.name',
                },
                {
                    name: 'datetime_in',
                    title: this.getI18n(i18nKey, 'TITLES.checkin'),
                    sortField: 'datetime_in',
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                },
                {
                    name: 'datetime_out',
                    title: this.getI18n(i18nKey, 'TITLES.checkout'),
                    sortField: 'datetime_out',
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                },
                {
                    name: 'time_in_store',
                    title: this.getI18n(i18nKey, 'FIELDS.time_in_store'),
                    formatter: (value) => `${value} min`,
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                },
                {
                    title: this.getI18n(i18nCommon, 'map'),
                    formatter: (value) => {
                        return this.formatMapLink(value)
                    },
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                },
                {
                    name: 'battery',
                    title: this.getI18n(i18nKey, 'FIELDS.battery'),
                },
                {
                    name: 'file',
                    title: this.getI18n(i18nCommon, 'photo'),
                },
                {
                    name: 'forced_checkout',
                    title: this.getI18n(i18nKey, 'FIELDS.forced_checkout'),
                    sortField: 'forced_checkout',
                    formatter: this.booleanIcons,
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                },
                {
                    name: 'details',
                    title: this.getI18nModified({
                        prefix: 'COMMON',
                        suffix: 'detail',
                        modifier: 2,
                    }),
                },
            ],
        }
    },
    methods: {
        distanceClass(percentile) {
            return (
                'text-center aligned font-weight-bold ' +
                (percentile >= 80
                    ? 'table-success'
                    : percentile >= 50
                    ? 'table-warning'
                    : 'table-danger')
            )
        },
    },
}
</script>
