<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue';
import BusinessUnitCheckService from '@src/services/BusinessUnitCheckService'
import PosCheckService from '@src/services/PosCheckService'
import Layout from '@layouts/main.vue'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import posCheckFilterModal from '@views/posCheck/posCheckFilterModal.vue'
import posCheckScriptModal from '@views/posCheck/posCheckScriptModal.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import businessUnitCheckFields from '@src/fields/businessUnitCheckFields'
import posCheckFields from '@src/fields/posCheckFields'
import filterVuetable from '@src/mixins/filterVuetable'
import ExportButton from '@/src/components/ExportButton.vue'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import BatteryTable from '@/src/components/BatteryTable.vue'
import DetailTableButton from '@/src/components/DetailTableButton.vue'

const i18nKey = 'POS_CHECK'
const i18nCommon = 'COMMON'

export default {
    components: {
        ApiModeVuetable,
        Layout,
        Breadcrumb,
        PageHeader,
        posCheckFilterModal,
        posCheckScriptModal,
        ExportButton,
        FilterLabelButton,
        BatteryTable,
        DetailTableButton,
    },
    mixins: [
        swalFeedback,
        businessUnitCheckFields,
        posCheckFields,
        filterVuetable
    ],
    data() {
        return {
            i18nCommon: i18nCommon,
            i18nKey: i18nKey,
            modalIsEdit: false,
            additionalParametersPos: {
                with: ['pointOfSale', 'user', 'file', 'fileOut'],
            },
            additionalParametersBusinessUnit: {
                with: ['user', 'businessUnit', 'pointOfSale'],
            },
            data: [],
            perPage: 10,
            css: {},
            tabIndex: 0,
            sortOrder: [
                {
                    field: 'datetime_in',
                    direction: 'desc',
                },
            ],
            filtersPos: {
                dateTimeIn: {},
                dateTimeOut: {},
                users: [],
                point_of_sales: [],
                business_units: [],
                chains: [],
            },
            filtersBusinessUnit: {
                dateTimeIn: {},
                dateTimeOut: {},
                users: [],
                point_of_sales: [],
                business_units: [],
                chains: [],
            },
        }
    },

    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: 'POS',
                        suffix: 'TITLES.pos',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.poscheck_summary',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nPosCheck() {
            return this.getI18n(i18nKey, 'TITLES.poscheck')
        },
        i18nBusinessUnitCheck() {
            return this.getI18n(i18nKey, 'TITLES.poscheck_business')
        },
        i18nNewCheck() {
            return this.getI18n(i18nKey, 'BUTTONS.new_poscheck')
        },
        i18nPos() {
            return this.getI18n('POS', 'TITLES.pos')
        },
        i18nBusinessUnit() {
            return this.getI18n('BUSINESS_UNITS', 'TITLES.business_unit')
        },
    },
    methods: {
        fetchPos(url, params) {
            return PosCheckService.fetchVuetable(url, params)
        },

        fetchBusinessUnit(url, params) {
            return BusinessUnitCheckService.fetchVuetable(url, params)
        },

        cleanFilter() {
            Object.assign(this.filters, {
                dateTimeIn: {},
                dateTimeOut: {},
                users: [],
                point_of_sales: [],
                business_units: [],
                chains: [],
            })

            this.additionalParametersPos = {
                with: ['pointOfSale', 'user', 'file', 'fileOut'],
            }

            this.additionalParametersBusinessUnit = {
                with: ['user', 'businessUnit', 'pointOfSale'],
            }

            this.refreshTable()
        },

        createAdditionalParametersPos() {
            return this.formatParameters({
                with: ['pointOfSale', 'user', 'file', 'fileOut'],
                user_id: this.filtersPos.users.map((unit) => unit.id),
                point_of_sale_id: this.filtersPos.point_of_sales.map((pos) => pos.id),
                business_unit_id: this.filtersPos.business_units.map((businessUnit) => businessUnit.id),
                chain_id: this.filtersPos.chains.map((chain) => chain.id),
                datetime_in: this.formatFilterDate(this.filtersPos.dateTimeIn),
                datetime_out: this.formatFilterDate(this.filtersPos.dateTimeOut),
            })
        },

        filterPos(appliedFilters) {
            this.filtersPos = appliedFilters
            this.additionalParametersPos = this.createAdditionalParametersPos()
            this.refreshTablePos()
        },

        showFilterPosModal() {
            this.$nextTick(() =>
                this.$refs.posCheckFilterPosModal.showModal(this.filtersPos)
            )
        },

        refreshTablePos() {
            this.$nextTick(() => {
                this.$refs.vuetablePos.refresh()
            })
        },

        createAdditionalParametersBusinessUnit() {
            return this.formatParameters({
                with: ['user', 'businessUnit', 'pointOfSale'],
                user_id: this.filtersBusinessUnit.users.map((unit) => unit.id),
                point_of_sale_id: this.filtersBusinessUnit.point_of_sales.map((pos) => pos.id),
                business_unit_id: this.filtersBusinessUnit.business_units.map((businessUnit) => businessUnit.id),
                chain_id: this.filtersBusinessUnit.chains.map((chain) => chain.id),
                datetime_in: this.formatFilterDate(this.filtersBusinessUnit.dateTimeIn),
                datetime_out: this.formatFilterDate(this.filtersBusinessUnit.dateTimeOut),
            })
        },

        filterBusinessUnit(appliedFilters) {
            this.filtersBusinessUnit = appliedFilters
            this.additionalParametersBusinessUnit = this.createAdditionalParametersBusinessUnit()
            this.refreshTableBusinessUnit()
        },

        showFilterBusinessUnitModal() {
            this.$nextTick(() =>
                this.$refs.posCheckFilterBusinessUnitModal.showModal(this.filtersBusinessUnit)
            )
        },

        refreshTableBusinessUnit() {
            this.$nextTick(() => {
                this.$refs.vuetableBusinessUnit.refresh()
            })
        },

        openModalScript(data) {
            this.$nextTick(() => this.$refs.posCheckScriptModal.showModal(data))
        }
    },
    metaInfo() {
        return {
            title: this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.poscheck',
                modifier: 2,
            }),
        }
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <b-tabs
                id="export-presentation-job-config-tabs"
                v-model="tabIndex"
                class="tabs-pdv mt-3">
                <b-tab :title="i18nPos">
                    <div class="row">
                        <div class="col-sm-6 mb-3 form-inline"></div>
                        <div class="col-sm-6 mb-3">
                            <div class="text-sm-right">
                                <filter-label-button @onClick="showFilterPosModal" />
                                <export-button
                                    v-if="$can('CREATE', 'EXPORTJOB')"
                                    type="point_of_sale_check"
                                    :create-parameters="createAdditionalParametersPos"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="card card-pdv">
                        <PageHeader :title="i18nPosCheck" />
                        <div class="card-body">
                            <api-mode-vuetable
                                ref="vuetablePos"
                                :fetch-data="fetchPos"
                                :fields="posCheckFields"
                                :per-page="perPage"
                                :display-selection-records-per-page="true"
                                :additional-parameters="additionalParametersPos"
                                :sort-order="sortOrder">
                                <template slot="battery" slot-scope="props">
                                    <b-row>
                                        <battery-table :value="props.rowData.battery_in" />
                                    </b-row>
                                    <b-row class="mt-1">
                                        <battery-table :value="props.rowData.battery_out" />
                                    </b-row>
                                </template>
                                <template slot="datetime_in" slot-scope="props">
                                    <!-- eslint-disable-next-line vue/no-v-html -->
                                    <div v-html="formatCheckInfo(props.rowData.datetime_in, props.rowData.distance_in)" />
                                </template>
                                <template slot="datetime_out" slot-scope="props">
                                    <!-- eslint-disable-next-line vue/no-v-html -->
                                    <div v-html="formatCheckInfo(props.rowData.datetime_out, props.rowData.distance_out)" />
                                </template>
                                <template slot="file" slot-scope="props">
                                    <b-row>
                                        {{ getI18n(i18nKey, 'FIELDS.in') }}
                                        <a v-if="props.rowData.file && props.rowData.file.url"
                                            :href="props.rowData.file.url"
                                            target="_blank">
                                            <i class="fe-camera ml-1"/>
                                        </a>
                                        <i v-else class="fe-camera-off ml-1" />
                                    </b-row>
                                    <b-row class="mt-1">
                                        {{ getI18n(i18nKey, 'FIELDS.out') }}
                                        <a v-if="props.rowData.fileOut && props.rowData.fileOut.url"
                                            :href="props.rowData.fileOut.url"
                                            target="_blank">
                                            <i class="fe-camera ml-1"/>
                                        </a>
                                        <i v-else class="fe-camera-off ml-1" />
                                    </b-row>
                                </template>
                                <template slot="details" slot-scope="props">
                                    <div class="table-button-container">
                                        <detail-table-button @onClick="openModalScript(props.rowData)" />
                                    </div>
                                </template>
                            </api-mode-vuetable>
                        </div>
                    </div>
                </b-tab>
                <b-tab :title="i18nBusinessUnit">
                    <div class="row">
                        <div class="col-sm-6 mb-3 form-inline"></div>
                        <div class="col-sm-6 mb-3">
                            <div class="text-sm-right">
                                <filter-label-button @onClick="showFilterBusinessUnitModal" />
                                <export-button
                                    v-if="$can('CREATE', 'EXPORTJOB')"
                                    type="business_unit_check"
                                    :create-parameters="createAdditionalParametersBusinessUnit"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="card card-pdv">
                        <PageHeader :title="i18nBusinessUnitCheck" />
                        <div class="card-body">
                            <api-mode-vuetable
                                ref="vuetableBusinessUnit"
                                :fetch-data="fetchBusinessUnit"
                                :fields="businessUnitCheckFields"
                                :per-page="perPage"
                                :display-selection-records-per-page="true"
                                :additional-parameters="additionalParametersBusinessUnit"
                                :sort-order="sortOrder">
                                <template slot="battery" slot-scope="props">
                                    <b-row>
                                        <battery-table :value="props.rowData.battery_in" />
                                    </b-row>
                                    <b-row class="mt-1">
                                        <battery-table :value="props.rowData.battery_out" />
                                    </b-row>
                                </template>
                            </api-mode-vuetable>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>
        </div>
        <pos-check-filter-modal ref="posCheckFilterPosModal" :handle-submit="filterPos" />
        <pos-check-filter-modal ref="posCheckFilterBusinessUnitModal" :handle-submit="filterBusinessUnit" />
        <pos-check-script-modal ref="posCheckScriptModal" />
    </Layout>
</template>
