<template>
    <b-row class="mr-1">
        <div class="progress battery-progress-pdv mr-1">
            <b-progress-bar v-if="value <= 20" :value="value" variant="danger" class="battery-rogress-pdv"></b-progress-bar>
            <b-progress-bar v-else-if="value <= 40" :value="value" variant="warning" class="battery-progress-pdv"></b-progress-bar>
            <b-progress-bar v-else-if="value <= 50" :value="value" variant="warning" class="battery-progress-pdv"></b-progress-bar>
            <b-progress-bar v-else-if="value <= 75" :value="value" variant="info" class="battery-progress-pdv"></b-progress-bar>
            <b-progress-bar v-else :value="value" variant="success" class="battery-progress-pdv"></b-progress-bar>
        </div>
        {{ value && value > 0 ? value : 0 }}%
    </b-row>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            required: false,
            default: 0
        },
    },
}
</script>
