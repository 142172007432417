<template>
    <form-modal
        ref="formModal"
        :title="titleModal"
        size="xl">
        <template v-slot:form>
            <form>
                <questionnaire-answers-modal
                    ref="questionnaireAnswersModal"
                    :questionnaire-answer="questionnaireAnswer"
                />
                <b-row class="d-flex justify-content-center">
                    <div>
                        <i class="fe-user mr-1"/>
                        {{ userDescription }}
                    </div>
                    <div class="ml-3">
                        <i class="fe-log-in icon-blue mr-1"/>
                        {{ formatDateTime(modal.datetime_in, false) }}
                    </div>
                    <div class="ml-3">
                        <i class="fe-log-out icon-red mr-1"/>
                        {{ formatDateTime(modal.datetime_out, false) }}
                    </div>
                </b-row>
                <data-mode-vuetable-pagination
                    ref="relationVuetable"
                    v-model="modal.questionnaire_answers"
                    :fields="fields"
                    :per-page="10"
                >
                    <template slot="questionnaire.name" slot-scope="props">
                        {{ props.rowData.questionnaire ? props.rowData.questionnaire.name : '' }}
                        <b-link
                            v-if="$can('READ', 'ANSWER')"
                            id="answers-modal"
                            href="javascript: void(0);"
                            class="action-icon"
                            @click="openModalAnswers(props.rowData)"
                        >
                            <i class="fe-list icon-blue" />
                        </b-link>
                    </template>
                </data-mode-vuetable-pagination>
            </form>
        </template>
    </form-modal>
</template>

<script>
import FormModal from '@/src/components/FormModal.vue'
import DataModeVuetablePagination from '@components/DataModeVuetablePagination'
import questionnaireAnswersModal from '@src/router/views/questionnaireAnswers/questionnaireAnswersModal'
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nKey = 'POS_CHECK'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        DataModeVuetablePagination,
        questionnaireAnswersModal,
    },
    mixins: [vuetableFormatter],
    data() {
        return {
            i18nKey,
            i18nCommon,
            modal: [],
            titleModal: '',
            userDescription: '',
            questionnaireAnswer: {
                id: 0,
                name: '',
            },
            fields: [
                {
                    name: 'business_unit.name',
                    title: this.getI18n(i18nCommon, 'business_unit'),
                    sortField: 'business_unit|business_unit.name',
                },
                {
                    name: 'product.name',
                    title: this.getI18n('PRODUCTS', 'TITLES.product'),
                    sortField: 'product|product.name',
                },
                {
                    name: 'questionnaire.name',
                    title: this.getI18n('QUESTIONNAIRES', 'TITLES.questionnaire'),
                    sortField: 'questionnaires|questionnaire.name',
                },
            ]
        }
    },
    computed: {
    },
    methods: {
        showModal(data) {
            this.modal = data
            this.titleModal = `${this.getI18n(i18nKey, 'TITLES.visit_summary')}: ${this.modal.pointOfSale.name}`
            this.userDescription = `${this.modal.user.name} (${this.modal.user.role[0].alias})`

            this.$refs.formModal.show()
        },
        openModalAnswers(data) {
            this.questionnaireAnswer.id = data.id
            this.questionnaireAnswer.name = data.questionnaire.name
            this.$nextTick(() => this.$refs.questionnaireAnswersModal.showModal())
        },
    },
}
</script>
