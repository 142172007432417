<template>
    <div>
        <div class="table-responsive">
            <vuetable
                ref="dataModeVuetable"
                class="table table-centered table-hover"
                :per-page="perPage"
                :fields="fields"
                :api-mode="false"
                :data-manager="dataManager"
                pagination-path="pagination"
                :no-data-template="getI18n('PAGINATION', 'no_records')"
                @vuetable:pagination-data="onPaginationData"
            >
                <template v-for="(_, slotName) of $scopedSlots" v-slot:[slotName]="scope">
                    <slot :id="slotName" :name="slotName" v-bind="scope"/>
                </template>
            </vuetable>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-5">
                <vuetable-pagination-info
                    ref="dataModePaginationInfo"
                    :info-template="i18nPagination()"
                    :no-data-template="getI18n('PAGINATION', 'no_records')"
                />
            </div>
            <div class="col-sm-12 col-md-7">
                <vuetable-pagination
                    ref="dataModePagination"
                    :css="css.pagination"
                    class="pagination justify-content-end"
                    @vuetable-pagination:change-page="onChangePage"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { orderBy, slice } from 'lodash'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'

import Pagination from '@src/mixins/pagination'
export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetablePaginationInfo,
    },
    mixins: [Pagination],
    props: {
        value: {
            type: Array,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
        perPage: {
            type: Number,
            default: 10,
        },
    },
    data() {
        return {
            css: {
                pagination: {
                    activeClass: 'btn-primary text-white',
                    pageClass: 'btn',
                    linkClass: 'btn',
                    icons: {
                        prev: '',
                        next: '',
                    },
                },
            }
        }
    },

    computed: {
        data: {
            get() {
                return this.value
            },
            set(newVal) {
                this.$emit('input', newVal)
            },
        },
    },
    methods: {
        onChangePage(page) {
            this.$refs.dataModeVuetable.changePage(page)
        },
        /**
         * Pagination info and pagination show
         */
        onPaginationData(paginationData) {
            this.$refs.dataModePagination.setPaginationData(paginationData)
            this.$refs.dataModePaginationInfo.setPaginationData(paginationData)
        },
        /**
         * Manage data sorting and show pagination
         */
        dataManager(sortOrder, pagination) {
            if (this.data.length < 1) return
            let local = this.data

            // sortOrder can be empty, so we have to check for that as well
            if (sortOrder.length > 0) {
                local = orderBy(
                    local,
                    sortOrder[0].sortField,
                    sortOrder[0].direction
                )
            }

            pagination = this.$refs.dataModeVuetable.makePagination(
                local.length,
                this.perPage
            )
            const from = pagination.from - 1
            const to = from + this.perPage

            return {
                pagination: pagination,
                data: slice(local, from, to),
            }
        },

        refresh() {
            this.$nextTick(() => {
                this.$refs.dataModeVuetable.refresh()
            })
        },

        resetData() {
            this.$nextTick(() => {
                this.$refs.dataModeVuetable.resetData()
                this.$refs.dataModePagination.resetData()
                this.$refs.dataModePaginationInfo.resetData()
            })
        },
    },
}
</script>
