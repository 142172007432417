<template>
    <form-modal ref="formModal" :title="titleModal" size="xl">
        <template v-slot:form>
            <questionnaire-answers-detail-modal
                ref="questionnaireAnswersDetailModal"
                :title="i18nAnswers"
            />
            <api-mode-vuetable
                ref="vuetable"
                :fetch-data="fetch"
                :fields="fields"
                :per-page="perPage"
                :additional-parameters="additionalParameters">
                <template slot="answer" slot-scope="props">
                    <count-table-button
                        v-if="props.rowData.answer.length > 0"
                        :value="props.rowData.answer.length"
                        @onClick="openModalAnswers(props.rowData)"
                    />
                </template>
            </api-mode-vuetable>
        </template>
    </form-modal>
</template>

<script>
import FormModal from '@/src/components/FormModal.vue'
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue';
import AnswersService from '@src/services/AnswersService'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import pagination from '@src/mixins/pagination'
import CountTableButton from '@/src/components/CountTableButton.vue'
import questionnaireAnswersDetailModal from '@src/router/views/questionnaireAnswers/questionnaireAnswersDetailModal'

const i18nKey = 'ANSWERS'

export default {
    components: {
        FormModal,
        ApiModeVuetable,
        CountTableButton,
        questionnaireAnswersDetailModal,
    },
    mixins: [vuetableFormatters, pagination],
    props: {
        questionnaireAnswer: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            initialAdditionalParameters: {
                with: [
                    'text',
                    'choice',
                    'number',
                    'date',
                    'file',
                    'boolean',
                    'question',
                    'product',
                    'productCategory',
                ],
                withNestedRelation: 'file.file',
            },
            additionalParameters: this.initialAdditionalParameters,
            fields: [
                {
                    name: 'question.question',
                    title: this.getI18n('QUESTIONS', 'TITLES.question'),
                    sortField: 'questions|questions.question',
                },
                {
                    name: 'answer',
                    title: this.getI18nModified({
                        prefix: 'ANSWERS',
                        suffix: 'TITLES.answer',
                        modifier: 2,
                    }),
                },
                {
                    name: 'type',
                    title: this.getI18n('QUESTIONS', 'FIELDS.type'),
                    sortField: 'type',
                    formatter: this.answerTypes,
                },
                {
                    name: 'product.name',
                    title: this.getI18n('PRODUCTS', 'TITLES.product'),
                    sortField: 'products|name',
                },
                {
                    name: 'product_category.name',
                    title: this.getI18n(
                        'PRODUCT_CATEGORIES',
                        'TITLES.product_category'
                    ),
                    sortField: 'product_categories|name',
                },
                {
                    name: 'answered_at_date_time',
                    title: this.getI18n(
                        i18nKey,
                        'FIELDS.answered_at_date_time'
                    ),
                    sortField: 'answered_at_date_time',
                    formatter: this.formatDateTime,
                },
            ],
            perPage: 5,
            css: {},
            hashs: [],
        }
    },
    computed: {
        titleModal() {
            return `${this.getI18n('QUESTIONNAIRES', 'TITLES.questionnaire')}: ${this.questionnaireAnswer.name}`
        },
        i18nAnswers() {
            return this.getI18nModified({
                prefix: 'COMMON',
                suffix: 'answer',
                modifier: 2,
            })
        },
        i18nImageNotUpload(){
            return this.getI18n('ERROR_CODES', 'image_not_uploaded')
        }
    },
    methods: {
        formatDate(value) {
            const date = new Date(value)
            return value
                ? new Date(
                      date.getTime() + date.getTimezoneOffset() * 60 * 1000
                  ).toLocaleDateString(this.$i18n.locale)
                : null
        },
        fetch(url, params) {
            return AnswersService.fetchVuetable(url, params)
        },
        answerTypes(type) {
            return this.getI18n('QUESTIONS', `TYPES.${type}`)
        },
        showModal() {
            this.additionalParameters = {
                ...this.initialAdditionalParameters,
                search: `questionnaire_answer_id:${this.questionnaireAnswer.id}`,
            }

            this.$refs.formModal.show()
        },
        openModalAnswers(data) {
            const answers = []

            for (let i = 0; i < data.answer.length; i++) {
                if (data.type === 'text') {
                    answers.push({
                        type: data.type,
                        name: data.answer[i].text,
                        additional_answer: null,
                        additional_type: null,
                    })
                }
                else if (data.type === 'choice') {
                    answers.push({
                        type: data.type,
                        name: data.answer[i].label,
                        additional_answer: data.answer[i].additional_answer,
                        additional_type: data.answer[i].additional_type,
                    })
                }
                else if (data.type === 'number') {
                    answers.push({
                        type: data.type,
                        name: data.answer[i].number,
                        additional_answer: null,
                        additional_type: null,
                    })
                }
                else if (data.type === 'date') {
                    answers.push({
                        type: data.type,
                        name: this.formatDate(data.answer[i].date),
                        additional_answer: null,
                        additional_type: null,
                    })
                }
                else if (data.type === 'boolean') {
                    answers.push({
                        type: data.type,
                        name: data.answer[i].boolean ? this.getI18n('COMMON', 'yes') : this.getI18n('COMMON', 'no'),
                        additional_answer: data.answer[i].additional_answer,
                        additional_type: data.answer[i].additional_type,
                    })
                }
                else if (data.type === 'file') {
                    if (data.answer[i].file) {
                        answers.push({
                            type: data.type,
                            name: data.answer[i].file.url,
                            additional_answer: null,
                            additional_type: null,
                        })
                    }
                    else {
                        answers.push({
                            type: 'text',
                            name: this.i18nImageNotUpload,
                            additional_answer: null,
                            additional_type: null,
                        })
                    }
                }
            }

            this.$refs.questionnaireAnswersDetailModal.showModal(data.question, answers)
        },
    },
}
</script>
