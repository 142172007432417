<template>
    <form-modal ref="formModal" :title="titleModal" size="lg">
        <template v-slot:form>
            <form>
                <b-table
                    hover
                    :fields="fields"
                    :items="itemsModal"
                    :current-page="currentPage"
                    :per-page="perPage"
                >
                    <template v-slot:cell(name)="row">
                        <template v-if="row.item.type === 'file'">
                            <a :href="row.item.name" target="_blank">
                                <i class="fe-external-link" />
                                {{ getI18n('COMMON', 'image') }}
                            </a>
                        </template>
                        <template v-else>
                            {{ row.item.name }}
                        </template>
                    </template>
                    <template v-slot:cell(additional_answer)="row">
                        <template v-if="row.item.additional_type === 'file'">
                            <a :href="row.item.additional_answer" target="_blank">
                                <i class="fe-external-link" />
                                {{ getI18n('COMMON', 'image') }}
                            </a>
                        </template>
                        <template v-else-if="row.item.additional_type === 'date'">
                            {{ formatDate(row.item.additional_answer) }}
                        </template>
                        <template v-else-if="row.item.additional_type === 'product_price'">
                            {{ formatPrice(row.item.additional_answer) }}
                        </template>
                        <template v-else>
                            {{ row.item.additional_answer }}
                        </template>
                    </template>
                </b-table>
                <b-pagination
                v-model="currentPage"
                :total-rows="itemsModal.length"
                :per-page="perPage"
                align="right"
                variant="danger"
                />
            </form>
        </template>
    </form-modal>
</template>

<script>
import FormModal from '@/src/components/FormModal.vue'
import vuetableFormatters from '@src/mixins/vuetableFormatters'

export default {
    components: {
        FormModal,
    },
    mixins: [vuetableFormatters],
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            gridModal: null,
            itemsModal: [],
            titleModal: this.title,
            perPage: 10,
            currentPage: 1,
            fields: [
                {
                    key: 'name',
                    label: this.getI18n('COMMON', 'answer'),
                    sortable: true,
                },
                {
                    key: 'additional_answer',
                    label: this.getI18nModified({
                        prefix: 'ANSWERS',
                        suffix: 'TITLES.additional_answer',
                        modifier: 2,
                    }),
                },
            ]

        }
    },
    methods: {
        hideModal() {
            this.$refs.formModal.hide()
        },
        showModal(question, items) {
            this.gridModal = question
            this.itemsModal = items
            if (this.gridModal.question) {
                this.titleModal = `${this.title} (${this.gridModal.question})`
            } else {
                this.titleModal = this.title
            }

            this.$refs.formModal.show()
        },
    },
}
</script>
